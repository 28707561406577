import { EmbraceTenantsConfiguration } from '../../Interfaces/Types';

export const fetchConfig = async (): Promise<EmbraceTenantsConfiguration> => {
  try {
    const baseConfigResponse = await fetch('/config.json');
    if (!baseConfigResponse.ok) {
      throw new Error('Failed to fetch base config');
    }
    const baseConfig = await baseConfigResponse.json();
    const response = await fetch(`${baseConfig.apiHost}/api/clientappconfig`);
    if (!response.ok) {
      throw new Error('Failed to fetch config');
    }
    const data = await response.json();
    const modifiedData: EmbraceTenantsConfiguration = {
      ...data,
      environments: data.environments.split(';'),
      host: baseConfig.apiHost,
    };
    return modifiedData;
  } catch (error) {
    console.error('Failed to fetch config:', error);
    throw error;
  }
};

import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { EmbraceTenantsConfiguration } from '../../Interfaces/Types';
import { fetchConfig } from './FetchConfig';

const ConfigContext = createContext<EmbraceTenantsConfiguration | undefined>(undefined);

interface ConfigProviderProps {
  children: ReactNode;
}

export const ConfigProvider: React.FC<ConfigProviderProps> = ({ children }) => {
  const [config, setConfig] = useState<EmbraceTenantsConfiguration>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const configSet = async () => {
      try {
        const config = await fetchConfig();
        setConfig(config);
      } catch (error) {
        console.error('Failed to fetch config:', error);
      } finally {
        setLoading(false);
      }
    };

    configSet();
  }, []);

  if (loading) {
    return <div>Loading configuration...</div>;
  }

  return <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>;
};

export const useConfig = () => useContext(ConfigContext);
import axios from 'axios'
import { useAccessToken } from '../Auth/AuthService'
import { useCallback } from 'react'
import { useConfig } from '../../Components/Config/ConfigContext'

interface ApiCallOptions {
  endpoint: string
  method: string
  body?: any // This can be an object if you're sending JSON, or undefined for GET requests
  additionalHeaders?: Record<string, string>
  token?: string | null // Optional access token for authentication
  // Specify that headers can have any string keys
}

export const useApiCall = () => {
  const config = useConfig()
  const accessToken = useAccessToken()

  const callApiWithToken = useCallback(
    async ({ endpoint, method, body, additionalHeaders = {}, token = accessToken }: ApiCallOptions) => {
      try {
        if (!config) {
          console.log('No config available.')
          return
        }
        if (!token) {
          console.log('No access token available.')
          return
        }
        if (!config?.host) {
          console.log('No host available in config.')
          return
        }

        // Prepare headers for the API request
        const headers: Record<string, string> = {
          ...additionalHeaders,
          Authorization: `Bearer ${token}`
        }

        // Add 'Content-Type' header for non-GET/DELETE requests with a body
        if (body && method.toUpperCase() !== 'GET' && method.toUpperCase() !== 'DELETE') {
          headers['Content-Type'] = 'application/json'
        }

        // Build Axios configuration
        const axConfig = {
          method: method,
          url: `${config.host}${endpoint}`,
          headers: headers,
          data: body
        }
        console.log(`Starting ${axConfig.method} request to ${axConfig.url}`)
        const response = await axios(axConfig)
        console.log(`Finished ${axConfig.method} request to ${axConfig.url}`)
        return response.data
      } catch (error) {
        // Handle errors, including Axios-specific errors
        if (axios.isAxiosError(error)) {
          console.error('API call failed:', error.message)
          throw error
        } else {
          console.error('Unexpected error:', error)
          throw error
        }
      }
    },
    [config, accessToken] // Dependencies for memoization
  )

  return { callApiWithToken }
}

import React, { useContext } from 'react'
import ListItem from '@embracesbs/component-listitem'
import List from '@embracesbs/component-list/List'
import Button2 from '@embracesbs/component-button2/Button2'
import { useApiCall } from '../../Utilities/ApiService/ApiService'
import { Subscription, Tenant } from '../../Interfaces/Types'
import Text from '@embracesbs/component-text'
import Container from '@embracesbs/component-container/Container'
import Icon from '@embracesbs/component-icon'
import { environmentLetters, itemBackgroundColor, itemColor } from '../../Utilities/helpers/helpers'
import Divider from '@embracesbs/component-divider'
import { AuthRoleContext } from '../Auth/AuthRoleContext'

interface TenantListProps {
  tenants?: Tenant[]
  selectedSubscription?: Subscription
  selectedTenant?: Tenant
  onSelectedTenant: (tenant?: Tenant) => void
  isSearch: boolean
  updateTenant: (tenant: Tenant) => void
}

const TenantList: React.FC<TenantListProps> = ({
  tenants,
  isSearch,
  selectedSubscription,
  selectedTenant,
  onSelectedTenant,
  updateTenant
}) => {
  const { callApiWithToken } = useApiCall();
  const roles = useContext(AuthRoleContext)
  const updateTenantCall = (tenant: Tenant) => {
    onSelectedTenant(undefined)
    tenant.deployStatus = 'feWaiting'
    updateTenant(tenant)
    tenant.deployStatus = 'updating'

    callApiWithToken({
      endpoint: `/api/tenant/${tenant?.id}`,
      method: 'PUT', // Your request payload
      body: JSON.stringify(tenant)
    }).then(() => {
      updateTenant(tenant)
    })
      .catch((error) => {
        tenant.deployStatus = 'updatingFailed'
        updateTenant(tenant)
        console.error(error)
      });
  }

  const renderPendingIcon = (tenant: Tenant) => {
    return tenant.deployStatus === 'feWaiting' ?
      <Icon
        color={itemColor(tenant, tenant.id === selectedTenant?.id)}
        iconName="EmailActionSync1"
        size={16}
        style={{
          margin: '0 9px 0 0 ',
          animation: 'spin 1s linear infinite'
        }} />
      :
      <Icon
        color={itemColor(tenant, tenant.id === selectedTenant?.id)}
        iconName="InProgress"
        size={16}
        style={{ margin: '0 9px 0 0 ' }} />
  }

  const renderList = () => {
    if (tenants === undefined || (selectedSubscription === undefined && !isSearch)) {
      return null
    }
    if (tenants.length > -1 || (isSearch && selectedSubscription !== undefined)) {
      if (tenants.length < 1) {
        return (
          <><Divider
            size="100%"
            spacing={{
              bottom: 20,
              top: 0
            }}
            style={{ background: '#f2f2f4', borderColor: '#416eec' }}
            text="Tenants"
          />
            <Text
              content={"No tenants found"}
              style={{ textAlign: 'center' }}
              textColor='grey'>
            </Text><br></br></>
        )
      }

      return (
        <>
          <Divider
            size="100%"
            spacing={{
              bottom: 20,
              top: 0
            }}
            style={{ background: '#f2f2f4', borderColor: '#416eec' }}
            text="Tenants"
          />
          <List ariaLabel='Test List'>
            {tenants.sort((a, b) => a.tenantName.localeCompare(b.tenantName))
              .map((item) => (
                <ListItem
                  id={item.id}
                  key={item.id}
                  visual={
                    <div style={{ marginLeft: '-12px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                      <div>
                        <Icon
                          color={itemColor(item, item.id === selectedTenant?.id)}
                          iconName="DataFileGraph"
                          size={10}
                        />
                      </div>
                      <p>{environmentLetters(item)}</p>
                    </div>
                  }
                  style={{
                    border: '1px solid ' + itemColor(item, item.id === selectedTenant?.id),
                    margin: '0 0 6px 0',
                    background: itemBackgroundColor(item.id === selectedTenant?.id)
                  }}
                  subTitle={item.deployStatus}
                  title={item.tenantName}
                  preset='oneLineIcon'
                  actions={
                    <Container>
                      {item.deployStatus === 'finished' ?
                        roles?.isAdmin && (
                          <Button2
                            className='launch-button'
                            buttonSize="30px"
                            leftElement={<Icon
                              className='launch-icon'
                              iconName="SpaceRocketFlying"
                              size={10} />
                            }
                            tabIndex={-1}
                            onClick={() => updateTenantCall(item)}
                            text=""
                            tooltip='Quick run this tenant with the same configuration'
                            type="button"
                            variant="subtle" />)
                        : renderPendingIcon(item)
                      }
                      <Button2
                        buttonSize="30px"
                        leftElement={<Icon
                          color='#416eec'
                          iconName="TaskOverview"
                          size={10} />
                        }
                        tabIndex={-1}
                        onClick={() => onSelectedTenant(item)}
                        text="details"
                        type="button"
                        variant="subtle" />
                    </Container>
                  }
                />
              ))}
          </List>
          <br></br>
        </>
      )
    }
    return <></>
  }

  return renderList()
}

export default TenantList

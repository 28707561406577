import React, { useEffect, useState } from 'react'
import Text from '@embracesbs/component-text'
import { useParams } from 'react-router-dom'
import { useApiCall } from '../Utilities/ApiService/ApiService'
import { FeatureFlag } from '../Interfaces/Types'
import { sortByLabel } from '../Utilities/helpers/helpers'

export default function FeatureFlags() {
  const [featureFlags, setFeatureFlags] = useState<FeatureFlag[] | null>()
  const { callApiWithToken } = useApiCall();
  let params = useParams()

  useEffect(() => {
    callApiWithToken({
      endpoint: `/api/appconfig/segments/${params.segment}/featureflags`,
      method: 'GET'
    })
      .then((response) => {
        setFeatureFlags(response.sort(sortByLabel))
      })
      .catch(function (error) {
        alert(error)
        console.error(error)
      })
  }, [params.segment])

  if (!featureFlags) {
    return (
      <Text>
        <b>Loading...</b>
      </Text>
    )
  } else {
    return (
      <form
        style={{
          margin: '20px auto',
          maxWidth: '1200px',
          width: '100%',
          padding: '30px 30px',
          justifyContent: 'space-around'
        }}
        id='featureflags'
      >
        <Text>
          <h1>Feature flags {params.segment}</h1>

          <ul
            style={{
              padding: '20px 17px'
            }}
          >
            {featureFlags.map((item) => (
              <li key={`${item.label}:${item.name}`}>
                {`${item.label} - ${item.name} - ${item.enabled.toString()}`}

                {item.enabledFor && (
                  <ul>
                    {item.enabledFor.map((enabledForItem) => (
                      <li key={`${item.label}:${enabledForItem}`}>{enabledForItem}</li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        </Text>
      </form>
    )
  }
}

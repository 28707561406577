import { createContext, useState, useEffect, ReactNode, FC } from 'react'
import { useApiCall } from '../../Utilities/ApiService/ApiService'
import { useAccessToken } from '../../Utilities/Auth/AuthService'

interface Roles {
  isAdmin: boolean
  isSecretReader: boolean
  isWriter: boolean
  isLister: boolean
}

interface AuthRoleContextProviderProps {
  children: ReactNode
}

const AuthRoleContext = createContext<Roles | null>(null)

const AuthRoleContextProvider: FC<AuthRoleContextProviderProps> = ({ children }) => {
  const [roles, setRoles] = useState<Roles | null>(null)
  const accesToken = useAccessToken();
  const { callApiWithToken } = useApiCall();
  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const response = await callApiWithToken({
          endpoint: `/api/getroles`,
          method: 'GET'
        })
        if (response !== undefined) {
          setRoles({
            isLister: response.includes('tenant.list'),
            isWriter: response.includes('tenant.write'),
            isAdmin: response.includes('tenant.admin'),
            isSecretReader: response.includes('tenant.readsecret')
          })
        }
      } catch (error) {
        alert(error)
        console.error(error)
      }
    }

    fetchRoles()
  }, [accesToken])

  return <AuthRoleContext.Provider value={roles}>{children}</AuthRoleContext.Provider>
}

export { AuthRoleContext, AuthRoleContextProvider }
